import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/RealmAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import store from './store';
import { ThemeProvider } from 'styled-components';
import { styledTheme } from './constants';
import { GlobalStyle } from './styles/global';
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import rtl from 'jss-rtl';
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ApolloProvider client={client}>
            <StyledEngineProvider injectFirst>
              <StylesProvider jss={jss}>
                <SettingsProvider>
                  <BrowserRouter>
                    <AuthProvider>
                      <ThemeProvider theme={styledTheme}>
                        <GlobalStyle />
                        <App />
                      </ThemeProvider>
                    </AuthProvider>
                  </BrowserRouter>
                </SettingsProvider>
              </StylesProvider>
            </StyledEngineProvider>
          </ApolloProvider>
        </LocalizationProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);
