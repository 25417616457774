import { FunctionComponent, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import {
  Wrapper,
  ImageWrapper,
  IconWrapper,
  IconsWrapper,
  ButtonsCardWrapper,
  Image,
  TextWrapper,
  SelectWrapper,
  FeaturedDiv,
  Select,
} from "./styled";
import { Typography } from "@material-ui/core";
import { styledTheme } from "src/constants";
import { ResponsiveImageType } from "react-datocms";
import { TRANSPORTATION_DATA } from "src/app/queries";
import TransportationPopup from "src/components/shared/transportation-popup";
import { toggleFromArray } from "src/utils/toggleFromArray";

type CardOptions =
  | {
      name: string;
      options: { name: string; value: string }[];
      initialValue: string;
    }[]
  | null
  | undefined;

export interface CardProps {
  image: ResponsiveImageType;
  title?: string;
  iconsNew?: { text1?: string | number; text2?: string }[];
  paragraph?: string;
  button1?: { text: string; onClick: () => void };
  button2: { text: string; onClick: (...args: any) => void };
  featured?: boolean;
  className?: string;
  selected?: boolean;
  price?: string | number | number;
  withQty?: boolean;
  initialQty?: number | string;
  maxQty?: number;
  withTransportationPopup?: {
    showPopup?: boolean;
    transportationPopupID: string;
    transportationfetched?: TRANSPORTATION_DATA;
    setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
    setTransportation: (
      value: React.SetStateAction<
        | {
            id?: string | undefined;
            qty?: number | string | undefined;
          }[]
        | null
      >
    ) => void;
    qty?: number | string;
  };
  withOptions?: CardOptions;
}

const Card: FunctionComponent<CardProps> = ({
  image,
  title,
  iconsNew,
  paragraph,
  button1,
  button2,
  featured,
  price,
  className,
  selected,
  withQty,
  initialQty,
  maxQty,
  withTransportationPopup,
  withOptions,
  ...props
}) => {
  const [qty, setQty] = useState<number | string | undefined>(initialQty);
  const [cardOptions, setCardOptions] = useState<{ [key: string]: string }>(
    withOptions
      ? withOptions?.reduce(
          (acc, curr, i) => ({
            [curr.name]: curr.initialValue,
            ...acc,
          }),
          {}
        )
      : null
  );

  console.log(cardOptions, "cardOptions");

  useEffect(() => {
    if (withOptions) {
      if (selected) {
        button2.onClick(qty, cardOptions);
        button2.onClick(qty, cardOptions);
      }
    }
  }, [cardOptions, qty]);

  return (
    <Wrapper className={className || ""} selected={selected}>
      {featured && (
        <FeaturedDiv
          style={{
            position: "absolute",
            right: "7.75px",
            top: "6.54px",
            zIndex: 1,
          }}
        >
          <img
            alt="star"
            src="/svg/starFeatured.svg"
            style={{
              width: "12px",
              marginRight: "2px",
              marginBottom: "0.6px",
            }}
          />
          Featured
        </FeaturedDiv>
        // <FeaturedIcon style={{ position: 'absolute', right: '-7px', top: '-7px', zIndex: 1, width: '23px' }} />
      )}
      <ImageWrapper>{image && <Image data={image} />}</ImageWrapper>
      <Typography
        variant="h6"
        component="h6"
        style={{
          marginBottom: "6.5px",
          fontSize: "14px",
          textAlign: "center",
          padding: "0 10px",
        }}
      >
        {title}
      </Typography>
      {iconsNew && (
        <IconsWrapper>
          {iconsNew?.map(({ text1, text2 }) => {
            if (!text1 || !text2) return null;
            return (
              <>
                <IconWrapper>
                  {/* <Icon /> */}
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{
                      marginBottom: "0px",
                      fontSize: "14px",
                      marginTop: "0px",
                      marginLeft: "4px",
                    }}
                  >
                    {text1}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{
                      marginBottom: "0px",
                      fontSize: "10px",
                      marginTop: "0px",
                      marginLeft: "4px",
                    }}
                  >
                    {text2}
                  </Typography>
                </IconWrapper>
              </>
            );
          })}
        </IconsWrapper>
      )}
      {paragraph && (
        <Typography
          style={{
            marginBottom: "0px",
            fontSize: "12px",
            textAlign: "center",
            color: styledTheme.colors.body,
            paddingLeft: "11px",
            paddingRight: "11px",
          }}
          dangerouslySetInnerHTML={{ __html: paragraph }}
        />
      )}
      <TextWrapper>
        <ButtonsCardWrapper>
          {button1 && (
            <Button
              size="large"
              onClick={button1.onClick}
              style={{
                minWidth: "110px",
                fontSize: "11px",
                fontWeight: "bold",
                textDecoration: "underline",
                color: styledTheme.colors.bodyLight,
              }}
            >
              {button1.text}
            </Button>
          )}
          {price && (
            <Typography
              variant="h5"
              component="p"
              style={{ fontSize: "16px", marginBottom: "8px" }}
            >
              {price}
            </Typography>
          )}
          {withQty && (
            <SelectWrapper>
              <Typography component="p">Quantity</Typography>
              <Select
                value={Number(qty)}
                onChange={(e) => {
                  setQty(e.target.value);
                  if (selected) {
                    button2.onClick(e.target.value, cardOptions);
                    button2.onClick(e.target.value, cardOptions);
                  }
                }}
              >
                {new Array(maxQty || 5)
                  .fill("beer")
                  .map((_, i) => ({ name: `${i}`, value: `${i}` }))
                  .map((option) => (
                    <option
                      key={`${option.name}-${title}`}
                      value={option.value}
                    >
                      {option.name}
                    </option>
                  ))
                  .slice(1)}
              </Select>
            </SelectWrapper>
          )}
          {withOptions &&
            withOptions.map(({ name, options }) => {
              return (
                <SelectWrapper>
                  <Typography
                    sx={{ textTransform: "capitalize" }}
                    component="p"
                  >
                    {name}
                  </Typography>
                  <Select
                    width={"90px"}
                    value={cardOptions[name]}
                    onChange={(e) => {
                      setCardOptions((prev) => ({
                        ...prev,
                        [name]: `${e.target.value}`,
                      }));
                    }}
                  >
                    {options.map((option) => (
                      <option
                        key={`${option.name}-${name}`}
                        value={option.value}
                      >
                        {option.name}
                      </option>
                    ))}
                  </Select>
                </SelectWrapper>
              );
            })}
          <Button
            size="small"
            variant="contained"
            color="secondary"
            style={{ minWidth: "100%", textTransform: "capitalize" }}
            onClick={() => {
              button2.onClick(qty, cardOptions);
            }}
          >
            {button2.text}
          </Button>
        </ButtonsCardWrapper>
      </TextWrapper>
    </Wrapper>
  );
};

export default Card;
