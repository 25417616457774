export const TilIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.635"
      height="15.608"
      viewBox="0 0 15.635 15.608"
      {...props}
    >
      <g id="l1ObG5" transform="translate(-998.958 -222.265)">
        <g
          id="Group_423"
          data-name="Group 423"
          transform="translate(998.959 222.265)"
        >
          <path
            id="Path_1183"
            data-name="Path 1183"
            d="M1001.381,223.45v-1.183h1.169v1.157h8.443v-1.159h1.171v1.184c.212,0,.39,0,.567,0a1.8,1.8,0,0,1,1.851,1.834q.024,5.382,0,10.764a1.773,1.773,0,0,1-1.821,1.814q-5.98.022-11.96,0a1.786,1.786,0,0,1-1.833-1.848q-.017-5.36,0-10.721a1.815,1.815,0,0,1,1.8-1.841C1000.95,223.442,1001.133,223.45,1001.381,223.45Zm12.017,4.819h-13.2a.612.612,0,0,0-.029.114c0,2.547-.006,5.093,0,7.64,0,.438.244.652.719.652q5.89.005,11.78,0c.518,0,.732-.232.733-.774q0-2.5,0-4.994C1013.4,230.04,1013.4,229.173,1013.4,228.269Zm-2.419-2.453v-1.152h-8.431v1.146h-1.189v-1.168c-.235,0-.419-.011-.6,0a.557.557,0,0,0-.581.521c-.033.62-.01,1.243-.01,1.858h13.2a.718.718,0,0,0,.03-.12q0-.64.005-1.28c0-.882-.324-1.133-1.228-.915v1.107Z"
            transform="translate(-998.958 -222.265)"
            fill="#727c83"
          />
          <path
            id="Path_1184"
            data-name="Path 1184"
            d="M1027.56,363.05h1.12v1.141h-1.12Z"
            transform="translate(-1025.116 -351.021)"
            fill="#727c83"
          />
          <path
            id="Path_1185"
            data-name="Path 1185"
            d="M1055.9,363.146h1.111v1.132H1055.9Z"
            transform="translate(-1051.032 -351.109)"
            fill="#727c83"
          />
          <path
            id="Path_1186"
            data-name="Path 1186"
            d="M1055.715,308.172v-1.1h1.118v1.1Z"
            transform="translate(-1050.865 -299.825)"
            fill="#727c83"
          />
          <path
            id="Path_1187"
            data-name="Path 1187"
            d="M1140.086,308.111v-1.1h1.128v1.1Z"
            transform="translate(-1128.028 -299.767)"
            fill="#727c83"
          />
          <path
            id="Path_1188"
            data-name="Path 1188"
            d="M1028.728,335.107v1.111h-1.134v-1.111Z"
            transform="translate(-1025.147 -325.465)"
            fill="#727c83"
          />
          <path
            id="Path_1189"
            data-name="Path 1189"
            d="M1056.814,336.261h-1.123v-1.108h1.123Z"
            transform="translate(-1050.844 -325.508)"
            fill="#727c83"
          />
          <path
            id="Path_1190"
            data-name="Path 1190"
            d="M1141.3,335.054v1.12h-1.128v-1.12Z"
            transform="translate(-1128.106 -325.417)"
            fill="#727c83"
          />
          <path
            id="Path_1191"
            data-name="Path 1191"
            d="M1084.013,363.085h2.5v2.5h-2.5Z"
            transform="translate(-1077.448 -351.732)"
            fill="#03a0ef"
          />
          <path
            id="Path_1192"
            data-name="Path 1192"
            d="M1112.074,335.019h1.111v1.122h-1.111Z"
            transform="translate(-1102.409 -325.385)"
            fill="#727c83"
          />
          <path
            id="Path_1193"
            data-name="Path 1193"
            d="M1083.971,306.928h1.1v1.109h-1.1Z"
            transform="translate(-1076.707 -299.694)"
            fill="#727c83"
          />
          <path
            id="Path_1194"
            data-name="Path 1194"
            d="M1112.011,308.1v-1.1h1.12v1.1Z"
            transform="translate(-1102.351 -299.766)"
            fill="#727c83"
          />
          <path
            id="Path_1195"
            data-name="Path 1195"
            d="M1084.119,335.031h1.092v1.117h-1.092Z"
            transform="translate(-1076.843 -325.396)"
            fill="#727c83"
          />
        </g>
      </g>
    </svg>
  );
};
