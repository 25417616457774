import { FunctionComponent, useState } from "react";
import { PriceService } from "src/services/price";
import { toggleFromObjArray } from "src/utils";
import { CardWrapper } from "src/components/shared";
import Person from "src/components/shared/icons/people";
import DACard from "src/components/shared/card";
import { TransportationTrip } from "src/types/group";

interface TransportationCardProps {
  transportations;
  transportationFetched;
  groupSize;
  tripLength;
  setTransportations;
}
const TransportationCard: FunctionComponent<TransportationCardProps> = ({
  transportations,
  transportationFetched,
  groupSize,
  tripLength,
  setTransportations,
  ...props
}) => {
  const selected = transportations
    ?.map((x) => x.id)
    ?.includes(transportationFetched?.id);
  const thisTransportation = transportations?.find(
    (x) => x.id == transportationFetched?.id
  );
  const qty = thisTransportation?.qty;
  const initialTrip = thisTransportation?.trip || "one-way";
  console.log(initialTrip, "initialTrip");
  const price =
    groupSize &&
    tripLength &&
    PriceService.transportation(
      {
        price: transportationFetched.price,
        groupSize,
        tripLength,
        activityType: transportationFetched.activityType,
        trip: transportationFetched.trip,
        isThisAnAirportTransfer: transportationFetched.isThisAnAirportTransfer,
      },
      {
        labeled: true,
        rounded: true,
        usd: true,
        noCeroCero: true,
      }
    );

  const cardProps = {
    image: transportationFetched.cardImage.responsiveImage,
    title: transportationFetched.name,
    withQty: true,
    initialQty: qty || 1,
    icons: [
      {
        Icon: Person,
        text: transportationFetched.groupSizeMin + "+",
      },
    ],
    paragraph: transportationFetched.shortDescription,
    // button1: { text: 'LEARN MORE', onClick: () => console.log('click') },
    button2: {
      text: selected ? "Remove Transportation" : "Select Transportation",
      onClick: (qty, cardOptions) =>
        setTransportations((prev) => {
          return toggleFromObjArray(prev, {
            ...transportationFetched,
            qty,
            ...cardOptions,
          });
        }),
    },
    featured: transportationFetched.featured,
    selected,
    price,
    withOptions: transportationFetched.isThisAnAirportTransfer && [
      {
        name: "trip",
        options: [
          { name: "One Way", value: "one-way" },
          { name: "Round Trip", value: "round-trip" },
        ],
        initialValue: initialTrip,
      },
    ],
  };
  return (
    <CardWrapper key={transportationFetched.id}>
      <DACard {...cardProps} />
    </CardWrapper>
  );
};

export default TransportationCard;
