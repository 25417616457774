import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { Label, LabelText } from 'src/components/shared/label';
import styled from 'styled-components';

export interface SelectProps {
  options: {
    name: string;
    value: string;
  }[];
  className?: string;
  label?: string;
  error?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  onChange?: Dispatch<SetStateAction<any>> | (() => void);
  customOnChange?: Dispatch<SetStateAction<any>> | (() => void);
}

const StyledSelect = styled.select`
  border-radius: 6px;
  cursor: pointer;
  font-family: 'Lato';
  background: ${({ theme }) => theme.colors.bgInput};
  border: 1px solid ${({ theme }) => theme.colors.bg};
  height: 40px;
  padding: 2px 20px;
  display: block;
  margin-bottom: 15px;
  width: 100%;
  margin-left: 0;
  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
    font-size: 16px;
  }
  svg {
    color: ${({ theme }) => theme.colors.primary};
    transform: rotate(90deg);
  }
  appearance: none;
  &::-ms-expand {
    display: none;
  }
`;

const StyledArrowRightAltIcon = styled(ArrowRightAltIcon)`
  position: absolute;
  bottom: 10px;
  right: 5px;
`;

const SelectWrapper: FunctionComponent<SelectProps> = React.forwardRef(
  (
    {
      options,
      className,
      label,
      error,
      disabled,
      style = {},
      customOnChange,
      ...props
    },
    ref: any
  ) => {
    return (
      <Label className={className || ''} style={style}>
        <LabelText>{label}</LabelText>
        {error && <p className="error-message">{error}</p>}
        <StyledArrowRightAltIcon
          color="primary"
          onClick={() => ref?.current?.click()}
        />
        <StyledSelect
          {...props}
          onChange={
            customOnChange
              ? (e) => customOnChange(e.target.value)
              : props.onChange
          }
          ref={
            ref as unknown as
              | ((instance: HTMLSelectElement | null) => void)
              | React.RefObject<HTMLSelectElement>
              | null
              | undefined
          }
          disabled={disabled}
        >
          {options.map((option) => (
            <option key={`${option.value}-${label}`} value={option.value}>
              {option.name}
            </option>
          ))}
        </StyledSelect>
      </Label>
    );
  }
);

export default SelectWrapper;
