import { useState, useCallback, useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { customerApi } from 'src/__fakeApi__/customerApi';
import CustomerEditForm2 from 'src/components/dashboard/customer/CustomerEditForm2';
import useMounted from 'src/hooks/useMounted';
import useSettings from 'src/hooks/useSettings';
import ChevronRightIcon from 'src/icons/ChevronRight';
import gtm from 'src/lib/gtm';
import type { Customer } from 'src/types/customer';
import { GROUP_QUERY } from 'src/hooks/queries';
import { useQuery } from '@apollo/client';

const CustomerEdit: FC = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const [customer, setCustomer] = useState<Customer | null>(null);

  const { groupId } = useParams();

  const {
    data: initialData,
    loading: initialLoading,
    refetch: initialRefetch,
  } = useQuery(GROUP_QUERY, {
    variables: {
      id: groupId,
    },
  });

  const getCustomer = useCallback(async () => {
    try {
      const data = await customerApi.getCustomer();

      if (mounted.current) {
        setCustomer(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  if (!customer) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard: Create Quote </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Create Quote
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/groups"
                  variant="subtitle2"
                >
                  Groups
                </Link>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box mt={3}>
            <CustomerEditForm2
              initialData={initialData}
              initialLoading={initialLoading}
              initialRefetch={initialRefetch}
              groupId={groupId}
              create
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomerEdit;
