import * as Realm from "realm-web";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  ServerError,
  ApolloLink,
  createHttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

const APP_ID = "wizard-qpxfp";
const graphqlUri = `https://services.cloud.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`;
// Connect to your MongoDB Realm app
const realmApp = new Realm.App(APP_ID);
// Gets a valid Realm user access token to authenticate requests
async function getAccessToken() {
  // Guarantee that there's a logged in user with a valid access token
  if (!realmApp.currentUser) {
    return null;
  } else {
    // An already logged in user's access token might be stale. To guarantee that the token is
    // valid, we refresh the user's custom data which also refreshes their access token.
    await realmApp.currentUser.refreshCustomData();
  }
  return {
    accessToken: realmApp?.currentUser?.accessToken,
    user: realmApp?.currentUser,
  };
}
// Configure the ApolloClient to connect to your app's GraphQL endpoint
const httpLink = new HttpLink({
  uri: graphqlUri,
  // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
  // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
  // access token before sending the request.
  fetch: async (uri, options) => {
    const { accessToken } = await getAccessToken();
    if (options && accessToken) {
      const newOptions = {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };
      return fetch(uri, newOptions);
    } else {
      throw new Error("Must log in...");
    }
  },
});
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    if ((networkError as ServerError).statusCode === 401) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
});

const token = process.env.REACT_APP_DATO_API_TOKEN;
const httpLinkDato = createHttpLink({
  fetch,
  uri: process.env.REACT_APP_DATOCMS_DELIVERY_URL,
});
const authLinkDato = setContext((_, { headers }) => {
  return {
    headers: Object.assign(headers || {}, {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    }),
  };
});

const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === "dato",
    authLinkDato.concat(httpLinkDato),
    from([errorLink, httpLink])
  ),
  cache: new InMemoryCache(),
});

export { client, realmApp, getAccessToken };
