export function toggleFromArray<T>(arr: T[] | null, item: T) {
  if (arr) {
    if (arr.includes(item)) {
      //remove clicked
      const newarr = [...arr];
      newarr.splice(arr.indexOf(item), 1);
      return newarr;
    } else {
      //add clicked
      return [...arr, item];
    }
  } else return [item]; //first clicked
}
