import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled('svg')``;

const Logo: FC<LogoProps> = (props) => (
  <LogoRoot
    version="1.1"
    viewBox="0.001 0.006 175.892 54.723"
    height="54.729"
    width="175.893"
    {...props}
  >
    <g>
      <defs>
        <clipPath id="svgcid-if00h-ayy5ui">
          <path d="M175.893 0v54.729H0V0h175.893z"></path>
        </clipPath>
      </defs>
      <g clip-path="url(#svgcid-if00h-ayy5ui)">
        <path
          fill="#ffffff"
          d="M80.982 31.172V9.749c0-1.211.413-1.579 1.765-1.579 5.8 0 11.6-.035 17.4.025a27.861 27.861 0 0 1 4.4.521 22.678 22.678 0 0 1 3.669.9 35.256 35.256 0 0 1 4.081 1.781 23.4 23.4 0 0 1 3.273 1.949 23.208 23.208 0 0 1 2.811 2.48 20.309 20.309 0 0 1 3.509 4.61 37.13 37.13 0 0 1 1.549 3.446 22.333 22.333 0 0 1 .874 2.516 8.474 8.474 0 0 1 .345 2.026c.047 1.776.074 3.555-.011 5.329a11.128 11.128 0 0 1-.553 2.462 30.755 30.755 0 0 1-.97 2.99 20.732 20.732 0 0 1-2.611 4.566 27.168 27.168 0 0 1-4.7 4.69 21.57 21.57 0 0 1-3.319 2.116 32.687 32.687 0 0 1-3.4 1.613 17.82 17.82 0 0 1-2.443.728c-1.086.273-2.187.5-3.281.746a2.686 2.686 0 0 1-.383.084c-1.374.1-2.749.271-4.124.279-5.271.035-10.543.018-15.815.015a4.233 4.233 0 0 1-.977-.085c-.883-.214-1.084-.475-1.084-1.306V31.169m6.669-.141v16.375c0 .793.023.807.875.806 3.1 0 6.193-.041 9.289 0a21.65 21.65 0 0 0 7.978-1.126 23.407 23.407 0 0 0 3.6-1.546 16.872 16.872 0 0 0 4.242-3.416 23.989 23.989 0 0 0 2.34-3.048 16.626 16.626 0 0 0 1.342-2.981 7.846 7.846 0 0 0 .64-2.438c.059-2.216.374-4.451-.243-6.649a16.187 16.187 0 0 0-.753-2.479 22.257 22.257 0 0 0-1.754-3.083 16.282 16.282 0 0 0-5.279-4.691 18.97 18.97 0 0 0-4.963-2.072 15.188 15.188 0 0 0-3.524-.62c-4.28-.085-8.563-.051-12.845-.067-.94 0-.943-.014-.944.842v16.2"
          data-color="1"
        ></path>
        <path
          fill="#ffffff"
          d="M130.233 30.905c0-.616.013-1.232 0-1.847a11.888 11.888 0 0 1 .561-3.548c.33-1.168.606-2.349.984-3.5a11.574 11.574 0 0 1 .933-2.064 34.119 34.119 0 0 1 2.357-3.895 21.3 21.3 0 0 1 3.058-3.212 44.4 44.4 0 0 1 3.99-2.955 1.317 1.317 0 0 1 1.938.238q1.268 1.421 2.4 2.934a1.446 1.446 0 0 1-.605 2.248 17.276 17.276 0 0 0-5.07 4.042 13.431 13.431 0 0 0-2.613 4.159c-.345.926-.764 1.835-1.021 2.779a16.094 16.094 0 0 0-.47 2.779c-.077.841-.021 1.692-.016 2.538a16.239 16.239 0 0 0 4.904 11.552 15.952 15.952 0 0 0 19.679 2.436 16.369 16.369 0 0 0 6.12-6.473 14.792 14.792 0 0 0 1.68-4.455 19.084 19.084 0 0 0 .369-5.56 17.859 17.859 0 0 0-4.937-10.556 14.733 14.733 0 0 0-5.337-3.656 1.688 1.688 0 0 1-.891-2.681c.73-1 1.512-1.965 2.3-2.928a1.44 1.44 0 0 1 1.879-.214c.961.524 1.939 1.035 2.834 1.638a22.555 22.555 0 0 1 4.687 4.081 24.07 24.07 0 0 1 3.977 6.368c.419 1.022.7 2.088 1.021 3.14.291.953.55 1.914.823 2.872a.672.672 0 0 1 .036.17c.033 1.784.067 3.568.09 5.351 0 .307-.054.613-.065.921a14.152 14.152 0 0 1-.79 3.925c-.262.79-.474 1.593-.721 2.386a5.282 5.282 0 0 1-.316.81 30.735 30.735 0 0 1-1.489 2.853 24.483 24.483 0 0 1-5.545 6.285 19.98 19.98 0 0 1-6.308 3.5 31.476 31.476 0 0 1-3.537 1.007 21.943 21.943 0 0 1-7.1.127 21.628 21.628 0 0 1-9.566-3.725 22.57 22.57 0 0 1-6.874-7.346 37.013 37.013 0 0 1-2.056-4.276 19.894 19.894 0 0 1-.7-2.8c-.174-.705-.369-1.407-.537-2.112a2.8 2.8 0 0 1-.053-.631c-.006-.9 0-1.809 0-2.713"
          data-color="1"
        ></path>
        <path
          fill="#ffffff"
          d="M30.409 7.409c3.734 0 7.468-.042 11.2.02 1.591.026 3.176.3 4.768.411a11.188 11.188 0 0 1 3.83.915 11.88 11.88 0 0 1 2.455 1.405 11.28 11.28 0 0 1 3.6 4.088 10.579 10.579 0 0 1 .872 2.352 11.809 11.809 0 0 1 .269 4.647c-.19.867-.129 1.781-.342 2.642a9.228 9.228 0 0 1-4.994 5.959c-.221.118-.5.137-.74.238-.123.053-.275.18-.282.281s.142.242.26.286c.462.173.934.329 1.412.464a9.146 9.146 0 0 1 6.178 5.538 12.212 12.212 0 0 1 .862 6.317 12.346 12.346 0 0 1-1.226 4.046 12.918 12.918 0 0 1-3.2 4.289 12.249 12.249 0 0 1-5.515 2.58 33.942 33.942 0 0 1-4.7.482c-8.6.568-17.2.1-25.8.263-.285.005-.57.044-.854.045-.654 0-.9-.358-.56-.843a206.978 206.978 0 0 1 2.252-3.118 1.479 1.479 0 0 1 1.326-.592c6.347.023 12.694.036 19.041 0a43.539 43.539 0 0 0 5.043-.305 8.663 8.663 0 0 0 3.876-1.137 11.639 11.639 0 0 0 2.417-2.222 8.34 8.34 0 0 0 1.516-6.62 7.07 7.07 0 0 0-4.037-5.394 16.08 16.08 0 0 0-2.3-.863c-.8-.28-.87-.409-.472-1.065a2.626 2.626 0 0 0-.26-3.276c-.546-.679-.45-.982.437-1.26a5.855 5.855 0 0 0 3.329-2.179 7.812 7.812 0 0 0 .916-2.239 15.054 15.054 0 0 0 .224-5 6.252 6.252 0 0 0-1.805-4.023 5.761 5.761 0 0 0-2.755-1.539 21.475 21.475 0 0 0-3.961-.711c-6.887-.45-13.778-.089-20.666-.174a2.192 2.192 0 0 1-2.189-1.1c-.524-.9-1.258-1.7-1.858-2.564-.144-.208-.258-.6-.141-.744a1.217 1.217 0 0 1 .838-.3c3.917-.022 7.827-.014 11.736-.014z"
          data-color="1"
        ></path>
        <path
          fill="#069fef"
          d="M34.485 34.382c-1.446-.068-2.432-.1-3.416-.167a1.3 1.3 0 0 0-1.245.621c-.958 1.367-1.9 2.746-2.885 4.095-1.182 1.613-2.416 3.2-3.613 4.8-.988 1.325-1.935 2.675-2.938 3.991-.73.958-1.563 1.854-2.274 2.823-.747 1.019-1.4 2.1-2.108 3.136-.115.168-.351.4-.5.388a.886.886 0 0 1-.574-.4 11.958 11.958 0 0 1-.853-1.793 1.42 1.42 0 0 1 .024-.976c.827-1.911 1.707-3.8 2.557-5.707a476.015 476.015 0 0 0 2.607-5.914 367.48 367.48 0 0 0 3.155-7.5 1.893 1.893 0 0 0 .016-1.325c-.665-1.643-1.448-3.247-2.138-4.882-.855-2.025-1.627-4.079-2.5-6.1-.885-2.054-1.848-4.08-2.774-6.12-.331-.73-.7-1.451-.96-2.2a1.643 1.643 0 0 1 .047-1.04 14.139 14.139 0 0 1 .812-1.739c.1-.184.263-.426.442-.46.144-.028.412.184.522.346.612.907 1.142 1.861 1.8 2.742.9 1.21 1.912 2.351 2.824 3.553 1.062 1.4 2.063 2.834 3.1 4.25q2.22 3.039 4.448 6.073a82.727 82.727 0 0 1 1.7 2.383 1.581 1.581 0 0 0 1.438.648c2.96.05 5.925.062 8.876.243a6.438 6.438 0 0 1 4.585 1.758 1.782 1.782 0 0 1 .226 1.991 4.29 4.29 0 0 1-3.19 1.9c-2.544.278-5.106.423-7.212.588"
          data-color="2"
        ></path>
        <path
          fill="#ffffff"
          d="M70.846 31.118v21.531c0 .933-.454 1.371-1.506 1.391q-1.745.032-3.491 0c-1.135-.019-1.624-.488-1.624-1.516q0-21.354.013-42.707a1.962 1.962 0 0 1 .327-1.11 1.412 1.412 0 0 1 .938-.512c1.358-.058 2.721-.04 4.082-.021a1.2 1.2 0 0 1 1.26 1.291v21.65"
          data-color="1"
        ></path>
        <path
          fill="#069fef"
          d="M17.352 34.728c-3.511-.04-7.022-.072-10.532-.126a1.31 1.31 0 0 0-1.24.7 39.973 39.973 0 0 1-1.994 2.841c-.2.261-.72.57-.971.5-.5-.138-.593-.658-.507-1.107a10.057 10.057 0 0 1 .431-1.6 2.137 2.137 0 0 0-.965-2.725 7.88 7.88 0 0 1-.75-.576c-1.111-.906-1.1-1.564.053-2.438a13.067 13.067 0 0 1 1.157-.782 1.059 1.059 0 0 0 .612-1.1c-.14-.872-.353-1.734-.5-2.6a2.084 2.084 0 0 1 .021-.979c.2-.6.568-.694 1.045-.241a13.26 13.26 0 0 1 1.321 1.532c.395.512.738 1.056 1.1 1.588a1.28 1.28 0 0 0 1.177.615c2.941-.052 5.881-.079 8.822-.119.768-.011 1.537-.068 2.3-.039a1.149 1.149 0 0 1 .815.335 21.014 21.014 0 0 1 1.276 2.53 1.459 1.459 0 0 1-.023 1.01c-.242.632-.593 1.231-.893 1.846-.507 1.04-.5 1.04-1.755.935"
          data-color="2"
        ></path>
        <path
          fill="#069fef"
          d="M152.097 7.504c.746 0 1.491.013 2.237 0 .453-.011.6.1.433.508-.7 1.748-1.387 3.5-2.07 5.258-.149.381-.256.774-.4 1.154a1.777 1.777 0 0 1-.254.356 1.671 1.671 0 0 1-.335-.389c-.315-.775-.588-1.563-.906-2.336a146.685 146.685 0 0 0-1.642-3.864c-.224-.5.163-.539.505-.551.811-.028 1.625-.009 2.437-.009z"
          data-color="2"
        ></path>
        <path
          fill="#069fef"
          d="M152.897.014c.593 0 1.189.03 1.779-.007a5.1 5.1 0 0 1 2.386.551c.158.068.38.259.358.358-.033.151-.229.3-.395.4a7.231 7.231 0 0 1-3.8.677 38.55 38.55 0 0 0-3.154.144 8.6 8.6 0 0 1-3.665-.514c-.178-.061-.3-.248-.449-.377.13-.084.266-.161.389-.252 1.283-.945 2.883-.777 4.382-.934A21.165 21.165 0 0 1 152.9.047z"
          data-color="2"
        ></path>
        <path
          fill="#069fef"
          d="M151.807 5.578c-.984.039-1.969.1-2.953.111-.618.01-.89-.274-.8-.8a2.388 2.388 0 0 1 .311-.978 1.183 1.183 0 0 1 .784-.388c.805-.054 1.62 0 2.427-.037a47.312 47.312 0 0 0 2.8-.239c.624-.068.963.136 1.034.682a.286.286 0 0 0 .019.054c.406 1.286.19 1.527-1.335 1.509-.765-.009-1.531 0-2.3 0v.089"
          data-color="2"
        ></path>
        <path
          fill="#069fef"
          d="M159.727 5.653c-.363.528-.647.958-.948 1.378-1 1.392-2 2.779-3 4.172-.184.258-.314.547-.506.8a1.407 1.407 0 0 1-.4.25c-.02-.156-.1-.333-.049-.467.3-.716.631-1.421.948-2.13.27-.606.581-1.2.788-1.823a2.7 2.7 0 0 1 1.439-1.63c.418-.208.828-.428 1.259-.613.1-.044.271.033.462.063"
          data-color="2"
        ></path>
        <path
          fill="#069fef"
          d="M149.34 12.306l-.087.138a1 1 0 0 1-.3-.156c-.394-.46-.773-.929-1.156-1.4-1.172-1.431-2.348-2.861-3.511-4.3a1.582 1.582 0 0 1-.181-.451 2.662 2.662 0 0 1 .6.054 4.663 4.663 0 0 1 3.028 2.963 23.7 23.7 0 0 0 1.251 2.394c.128.243.235.495.356.753"
          data-color="2"
        ></path>
        <path
          fill="#069fef"
          d="M156.871 3.298c.045-.142.039-.332.133-.385.655-.368 1.32-.726 2.011-1.039.107-.049.392.112.533.231a3.417 3.417 0 0 1 .461.556c.4.512.3.887-.295 1.191-.511.259-1.016.53-1.538.769-.486.224-.771.1-.93-.36-.056-.162-.151-.314-.214-.475s-.108-.325-.161-.488"
          data-color="2"
        ></path>
        <path
          fill="#069fef"
          d="M144.932 2.62a8.6 8.6 0 0 1 1.4.58.963.963 0 0 1 .33.74 2.192 2.192 0 0 1-.254.882.5.5 0 0 1-.737.25c-.466-.21-.93-.422-1.4-.624a.745.745 0 0 1-.484-1.056 1.11 1.11 0 0 1 1.147-.772"
          data-color="2"
        ></path>
      </g>
    </g>
  </LogoRoot>
);

export default Logo;
