import styled from 'styled-components';
import { md } from 'src/constants';

export const Label = styled.label`
  width: 100%;
  display: block;
  position: relative;
  @media ${md} {
    max-width: 300px;
  }
`;

export const LabelText = styled.span`
  color: ${({ theme }) => theme.colors.bgDarker};
  font-size: 12px;
  margin-bottom: 7px;
  text-transform: uppercase;
  font-weight: 700;
`;
