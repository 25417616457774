export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};

//breakpoints
export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

//media queries
const mediaQuery = (breakpoint: string) =>
  `only screen and (min-width: ${breakpoint}px)`;

export const xs = mediaQuery(`${breakpoints.values.xs}`);
export const sm = mediaQuery(`${breakpoints.values.sm}`);
export const md = mediaQuery(`${breakpoints.values.md}`);
export const lg = mediaQuery(`${breakpoints.values.lg}`);
export const xl = mediaQuery(`${breakpoints.values.xl}`);

// colors. If edited make sure to update src/styled.d.ts as well.
export const colors = {
  primaryLight: '#03A0EF',
  primary: '#03A0EF',
  secondary: '#353743',
  tertiary: '#CF2C78',
  bgLight: '#F0F1F2',
  bgInput: '#F6F7F9',
  placeholder: '#E3E2E2',
  bg: '#EAEAEA',
  bgDark: '#353743',
  bgDarker: '#727C83',
  bodyLight: '#BAC3C9',
  body: '#353743',
};

export const styledTheme = { colors, sm, md, lg, xl };

const now = new Date().getTime();
const aDayinms = 3600 * 1000 * 24;
const aWeekInms = aDayinms * 7;
const aMonthInms = aDayinms * 30;
const aYearInms = aMonthInms * 12;

export const timeFrames = {
  aDayAgo: new Date(now - aDayinms).toISOString(),
  aWeekAgo: new Date(now - aWeekInms).toISOString(),
  aMonthAgo: new Date(now - aMonthInms).toISOString(),
  aYearAgo: new Date(now - aYearInms).toISOString(),
  beginingOfTimes: new Date(
    new Date().getTime() - new Date().getTime()
  ).toISOString(),
};
