import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import {
  LEAD_CAPTURING_CONFIG_QUERY,
  DESTINATIONS_QUERY,
  BUILDER_HOUSING_QUERY_DATA,
  BUILDER_HOUSING_QUERY,
  BUILDER_TRANSPORTATION_QUERY_DATA,
  BUILDER_TRANSPORTATION_QUERY,
  BUILDER_DAYTIMEACTIVITIES_QUERY_DATA,
  BUILDER_DAYTIMEACTIVITIES_QUERY,
  BUILDER_NIGHTTIMEACTIVITIES_QUERY_DATA,
  BUILDER_NIGHTTIMEACTIVITIES_QUERY,
  BUILDER_PERSONALSERVICES_QUERY_DATA,
  BUILDER_PERSONALSERVICES_QUERY,
} from "./queries";

interface Destination {
  country: string;
  place: string;
  id: string;
  __typename: "DestinationRecord";
}

interface DestinationsData {
  allDestinations: Destination[];
}

interface useEditGroupI {
  housingData: any;
  loadingHousing: boolean;
  transportationData: any;
  loadingTransportation: boolean;
  daytimeActivitiesData: any;
  loadingDaytimeActivities: boolean;
  nighttimeActivitiesData: any;
  loadingNighttimeActivities: boolean;
  personalServicesData: any;
  loadingPersonalServices: boolean;
  destinations: any;
  destinationOptions: any[];
  reasons: any[];
  options: any[];
  methods: any[];
  budgetOptions: any[];
  housingOptions: any[];
  transportationOptions: any[];
}

const useEditGroup = (watch): useEditGroupI => {
  const { data: leadCapData, loading: loadingLead } = useQuery(
    LEAD_CAPTURING_CONFIG_QUERY,
    {
      context: { clientName: "dato" },
    }
  );

  const {
    data: destinations,
    loading: loadingDestinations,
  }: { data?: DestinationsData; loading: boolean } = useQuery(
    DESTINATIONS_QUERY,
    {
      context: { clientName: "dato" },
    }
  );

  const { data: housingData, loading: loadingHousing } =
    useQuery<BUILDER_HOUSING_QUERY_DATA>(BUILDER_HOUSING_QUERY, {
      context: { clientName: "dato" },
      variables: {
        destination: `${watch(["destination"])[0]}`,
        groupSize: watch(["groupSize"])[0],
      },
    });

  const { data: transportationData, loading: loadingTransportation } =
    useQuery<BUILDER_TRANSPORTATION_QUERY_DATA>(BUILDER_TRANSPORTATION_QUERY, {
      context: { clientName: "dato" },
      variables: {
        destination: `${watch(["destination"])[0]}`,
        groupSize: watch(["groupSize"])[0],
      },
    });

  const { data: daytimeActivitiesData, loading: loadingDaytimeActivities } =
    useQuery<BUILDER_DAYTIMEACTIVITIES_QUERY_DATA>(
      BUILDER_DAYTIMEACTIVITIES_QUERY,
      {
        context: { clientName: "dato" },
        variables: {
          destination: `${watch(["destination"])[0]}`,
          groupSize: watch(["groupSize"])[0],
        },
      }
    );

  const { data: nighttimeActivitiesData, loading: loadingNighttimeActivities } =
    useQuery<BUILDER_NIGHTTIMEACTIVITIES_QUERY_DATA>(
      BUILDER_NIGHTTIMEACTIVITIES_QUERY,
      {
        context: { clientName: "dato" },
        variables: {
          destination: `${watch(["destination"])[0]}`,
          groupSize: watch(["groupSize"])[0],
        },
      }
    );

  const { data: personalServicesData, loading: loadingPersonalServices } =
    useQuery<BUILDER_PERSONALSERVICES_QUERY_DATA>(
      BUILDER_PERSONALSERVICES_QUERY,
      {
        context: { clientName: "dato" },
        variables: {
          destination: `${watch(["destination"])[0]}`,
          groupSize: watch(["groupSize"])[0],
        },
      }
    );

  let destinationOptions = [{ name: "Select one...", value: "" }];
  const fetchedDestinations = destinations?.allDestinations?.map(
    (destination) => {
      const { country, place, id } = destination;
      return { name: `${place} - ${country}`, value: id, ...destination };
    }
  );
  if (fetchedDestinations) {
    destinationOptions.push(...fetchedDestinations);
  }
  const reasons = leadCapData?.leadCapturingConfig?.reasonsForTravel
    ? [
        { name: "Select one...", value: "" },
        ...leadCapData?.leadCapturingConfig?.reasonsForTravel
          .split(",")
          .map((reason) => ({ name: reason, value: reason })),
      ]
    : [{ name: "Select one...", value: "" }];

  const options = [
    { name: "Select one...", value: "" },
    ...new Array(30)
      .fill("beer")
      .map((_, i) => ({ name: `${i + 1}`, value: `${i + 1}` })),
  ];

  const methods = [
    { name: "Select one...", value: "" },
    { name: "Email", value: "email" },
    { name: "Text", value: "text" },
    { name: "Call", value: "call" },
  ];

  const budgetOptions = useMemo(
    () =>
      leadCapData?.leadCapturingConfig?.budgets
        ? [
            { name: "Select one...", value: "" },
            ...leadCapData?.leadCapturingConfig?.budgets.map((budget) => ({
              name: `${budget.name} - ${budget.budgetRange}`,
              value: budget.name,
            })),
          ]
        : [{ name: "Select one...", value: "" }],
    [leadCapData?.leadCapturingConfig?.budgets]
  );

  const housingOptions =
    housingData?.allHousings.map((housing) => {
      return { value: housing.id, name: housing.name };
    }) || [];

  const transportationOptions =
    transportationData?.allTransportations.map((transportation) => {
      return { value: transportation.id, name: transportation.name };
    }) || [];

  return {
    housingData,
    loadingHousing,
    transportationData,
    loadingTransportation,
    daytimeActivitiesData,
    loadingDaytimeActivities,
    nighttimeActivitiesData,
    loadingNighttimeActivities,
    personalServicesData,
    loadingPersonalServices,
    destinations,
    destinationOptions,
    reasons,
    options,
    methods,
    budgetOptions,
    housingOptions,
    transportationOptions,
  };
};

export default useEditGroup;
