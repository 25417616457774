import { gql } from "@apollo/client";
import { ResponsiveImageType } from "react-datocms";
import { ActivityType, TransportationTrip } from "src/types/group";

export const LEAD_CAPTURING_CONFIG_QUERY = gql`
  {
    leadCapturingConfig {
      reasonsForTravel
      budgets {
        id
        name
        budgetRange
      }
    }
  }
`;
export const DESTINATIONS_QUERY = gql`
  {
    allDestinations(filter: { available: { eq: true } }) {
      place
      id
      country
    }
  }
`;

export const imageFragment = `responsiveImage(
imgixParams: { fit: clamp, w: 1, h: 1, auto: format }
) {
# HTML5 src/srcset/sizes attributes
srcSet
webpSrcSet
sizes
src
# size information (post-transformations)
width
height
aspectRatio
# SEO attributes
alt
title
# background color placeholder or...
bgColor
# blur-up placeholder, JPEG format, base64-encoded
base64
}`;

export type BUILDER_HOUSING_QUERY_DATA = {
  allHousings: Array<{
    id: string;
    available: boolean;
    groupSizeMax: number;
    groupSizeMin: number;
    featured: boolean;
    destination: {
      available: boolean;
      id: string;
      seasonalPricing: boolean;
      peakSeasonEnds: string | null;
      peakSeasonStarts: string | null;
      highSeasonStarts: string | null;
      highSeasonEnds: string | null;
    };
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
    bathrooms: number;
    sleeps: number;
    priceLow: number;
    priceHigh: number;
    priceForHoliday: number;
    name: string;
    shortDescription: string;
  }>;
};

export const BUILDER_HOUSING_QUERY = gql`
query housingForGroup($destination: ItemId!, $groupSize: IntType!) {
allHousings(
  orderBy: featured_DESC,
  filter: {
    destination: { eq: $destination }
    groupSizeMax: { gte: $groupSize }
    groupSizeMin: { lte: $groupSize }
  },
) {
  id
  available
  groupSizeMax
  groupSizeMin
  featured
  destination {
    available
    id
    seasonalPricing
    peakSeasonEnds
    peakSeasonStarts
    highSeasonStarts
    highSeasonEnds
  }
  cardImage {
      ${imageFragment}
  }
  bathrooms
  sleeps
  priceLow
  priceHigh
  priceForHoliday
  name
  shortDescription
}
}
`;

export type BUILDER_TRANSPORTATION_QUERY_DATA = {
  allTransportations: Array<{
    id: string;
    available: boolean;
    groupSizeMax: number;
    groupSizeMin: number;
    featured: boolean;
    isThisAnAirportTransfer: boolean;
    activityType: ActivityType;
    destination: {
      available: boolean;
      id: string;
      seasonalPricing: boolean;
      peakSeasonEnds: string | null;
      peakSeasonStarts: string | null;
      highSeasonStarts: string | null;
      highSeasonEnds: string | null;
    };
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
    price: number;
    name: string;
    description: string;
  }>;
};

export const BUILDER_TRANSPORTATION_QUERY = gql`
  query transportationForGroup($destination: ItemId!, $groupSize: IntType!) {
    allTransportations(
      filter: {
        destination: { eq: $destination }
        groupSizeMax: { gte: $groupSize }
        groupSizeMin: { lte: $groupSize }
      }
    ) {
      id
      name
      price
      available
      groupSizeMax
      groupSizeMin
      description
      isThisAnAirportTransfer
      activityType
      destination {
        available
        id
        seasonalPricing
        peakSeasonEnds
        peakSeasonStarts
        highSeasonStarts
        highSeasonEnds
      }
      cardImage {
        ${imageFragment}
      }
    }
  }
`;

export type BUILDER_DAYTIMEACTIVITIES_QUERY_DATA = {
  allDaytimeActivities: Array<{
    id: string;
    available: boolean;
    groupSizeMax: number;
    groupSizeMin: number;
    activityType: ActivityType;
    featured: boolean;
    destination: {
      available: boolean;
      id: string;
      seasonalPricing: boolean;
      peakSeasonEnds: string | null;
      peakSeasonStarts: string | null;
      highSeasonStarts: string | null;
      highSeasonEnds: string | null;
    };
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
    price: number;
    name: string;
    shortDescription: string;
  }>;
};

export const BUILDER_DAYTIMEACTIVITIES_QUERY = gql`
query activitiesForGroup($destination: ItemId!, $groupSize: IntType!) {
  allDaytimeActivities(
    filter: {
      destination: { eq: $destination }
      groupSizeMax: { gte: $groupSize }
      groupSizeMin: { lte: $groupSize }
    }
  ) {
    id
    name
    price
    available
    groupSizeMax
    groupSizeMin
    shortDescription
    activityType
    destination {
      available
      id
      seasonalPricing
      peakSeasonEnds
      peakSeasonStarts
      highSeasonStarts
      highSeasonEnds
    }
    cardImage {
      ${imageFragment}
    }
  }
}
`;

export type BUILDER_NIGHTTIMEACTIVITIES_QUERY_DATA = {
  allNighttimeActivities: Array<{
    id: string;
    available: boolean;
    groupSizeMax: number;
    groupSizeMin: number;
    activityType: ActivityType;
    featured: boolean;
    destination: {
      available: boolean;
      id: string;
      seasonalPricing: boolean;
      peakSeasonEnds: string | null;
      peakSeasonStarts: string | null;
      highSeasonStarts: string | null;
      highSeasonEnds: string | null;
    };
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
    price: number;
    name: string;
    shortDescription: string;
  }>;
};

export const BUILDER_NIGHTTIMEACTIVITIES_QUERY = gql`
query nighttymeActivitiesForGroup($destination: ItemId!, $groupSize: IntType!) {
  allNighttimeActivities(
    filter: {
      destination: { eq: $destination }
      groupSizeMax: { gte: $groupSize }
      groupSizeMin: { lte: $groupSize }
    }
  ) {
    id
    name
    price
    available
    groupSizeMax
    groupSizeMin
    shortDescription
    activityType
    destination {
      available
      id
      seasonalPricing
      peakSeasonEnds
      peakSeasonStarts
      highSeasonStarts
      highSeasonEnds
    }
    cardImage {
      ${imageFragment}
    }
  }
}
`;

export type BUILDER_PERSONALSERVICES_QUERY_DATA = {
  allPersonalServices: Array<{
    id: string;
    available: boolean;
    groupSizeMax: number;
    groupSizeMin: number;
    activityType: ActivityType;
    featured: boolean;
    destination: {
      available: boolean;
      id: string;
      seasonalPricing: boolean;
      peakSeasonEnds: string | null;
      peakSeasonStarts: string | null;
      highSeasonStarts: string | null;
      highSeasonEnds: string | null;
    };
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
    price: number;
    name: string;
    shortDescription: string;
  }>;
};

export const BUILDER_PERSONALSERVICES_QUERY = gql`
query personalServicesForGroup($destination: ItemId!, $groupSize: IntType!) {
  allPersonalServices(
    filter: {
      destination: { eq: $destination }
      groupSizeMax: { gte: $groupSize }
      groupSizeMin: { lte: $groupSize }
    }
  ) {
    id
    name
    price
    available
    activityType
    groupSizeMax
    groupSizeMin
    shortDescription
    destination {
      available
      id
      seasonalPricing
      peakSeasonEnds
      peakSeasonStarts
      highSeasonStarts
      highSeasonEnds
    }
    cardImage {
      ${imageFragment}
    }
  }
}
`;

export const GROUP_QUERY = gql`
  query groupById($id: ObjectId!) {
    group(query: { _id: $id }) {
      _id
      bido_id
      firstName
      payees
      lastName
      email
      phone
      destination {
        id
        country
        place
      }
      housing {
        id
        name
        priceForHoliday
        priceHigh
        priceLow
      }
      transportation {
        id
        name
        price
        qty
        trip
      }
      daytimeActivities {
        id
        name
        price
      }
      nighttimeActivities {
        id
        name
        price
      }
      personalServices {
        id
        name
        price
        qty
      }
      fromDate
      untilDate
      travelReason
      groupSize
      budget
      contactMethod
      status
      approval_status
      archived
      notes
      checklist {
        name
        status
      }
    }
  }
`;
