import React, { FunctionComponent } from 'react';
import { DatePicker, DatePickerProps } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { Label, LabelText } from 'src/components/shared/label';
import styled from 'styled-components';
import moment from 'moment';

export interface DateProps {
  label?: string;
  className?: string;
  datePickerProps: any;
  icon: React.ReactNode;
  error?: string;
  minDate?: Date;
  disabled?: boolean;
}

const StyledDatePicker = styled(DatePicker)`
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.bgInput};
  border: 1px solid ${({ theme }) => theme.colors.bg};
  height: 40px;
  padding: 0px 0px 0px 20px;
  display: block;
  margin-bottom: 15px;
  width: 100%;
  cursor: pointer;
  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
    font-size: 16px;
  }
  .MuiInput-underline::before {
    border-bottom: none !important;
  }
`;

const DateWrapper: FunctionComponent<DateProps> = ({
  label,
  disabled,
  className,
  children,
  datePickerProps,
  icon,
  error,
  minDate = new Date().toISOString(),
  ...props
}) => {
  return (
    <Label className={className || ''}>
      <LabelText>{label}</LabelText>
      {error && <p className="error-message">{error}</p>}
      <StyledDatePicker
        {...datePickerProps}
        inputFormat="MM / DD / YY"
        minDate={moment(minDate)}
        // OpenPickerIcon={icon}
        // placeholder={`       /       /   `}
        disabled={disabled}
        renderInput={(params) => <TextField {...params} />}
      />
    </Label>
  );
};

export default DateWrapper;
