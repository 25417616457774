import styled from 'styled-components';
import { md } from 'src/constants';

export const DateWrapper = styled.div`
  @media ${md} {
  }
  .MuiFormControl-root {
    height: auto;
    width: 100%;
  }
`;

export const Cards = styled.div`
  opacity: 0;
  display: flex;
  flex-wrap: wrap;
  animation: fadein forwards 1s;
`;
