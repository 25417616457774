import { CalcMode } from "../../types/group";
import { daysDiff, toUSD } from "../../utils";
import moment from "moment";
import {
  ComputeBasePrice,
  HousingPriceData,
  TransportationPriceData,
  PriceOptions,
  DaytimeActivitiesData,
  NighttimeActivitiesData,
  PersonalServicesData,
  ComputeSeasonalPrice,
} from "./types";

/**
 * Computes a base price depending on the mode, activity, and group information.
 *
 * Example 1: let an activity have the price representing a flat amount as a group expense, by setting the mode to "perPerson", resulting basePrice would be the price divided by the groupSize.
 *
 * Example 2: let an activity have the price representing the cost per person, by setting the mode to "total", resulting base price would be the price multiplied by the gorup size.
 * @param data to calculate price
 * @param mode Defines either individual or group calculations
 * @returns
 */
const computeBasePrice = (
  { price, activityType, groupSize }: ComputeBasePrice,
  mode?: CalcMode
) => {
  let basePrice = 0;
  switch (mode) {
    case "total":
      if (activityType === "group") {
        //price is in group format and the total is being requested, base price should be then the price.
        basePrice = Number(price);
      } else if (activityType === "individual") {
        basePrice = Number(price) * Number(groupSize);
      } else {
        throw new Error(
          `activityType:${activityType} is not valid, must be either  "group" or "individual"`
        );
      }
      break;
    case "perPerson":
      if (activityType === "group") {
        //price is in group format and the total is being requested, base price should be then the price.
        basePrice = Number(price) / Number(groupSize);
      } else if (activityType === "individual") {
        basePrice = Number(price);
      } else {
        throw new Error(
          `activityType:${activityType} is not valid, must be either  "group" or "individual"`
        );
      }
      break;
  }
  return basePrice;
};

const computePriceWithOptions = (
  price: string | number,
  options?: PriceOptions
) => {
  let computedPrice = price;
  const mode = options?.mode || "perPerson"; //default to perPerson calculation
  if (options?.rounded) computedPrice = Math.ceil(Number(computedPrice));
  if (options?.usd) computedPrice = toUSD(computedPrice);
  if (options?.noCeroCero)
    computedPrice = `${computedPrice}`.replace(/\D00(?=\D*$)/, "");
  if (options?.labeled && mode === "perPerson") {
    computedPrice = `${computedPrice} /person`;
  }
  return computedPrice;
};

/**
 *
 * @param any
 * @returns Total price of housing per person, accounting for seasonal pricing and total length of trip.
 */
function computeSeasonalPrice({
  peakSeasonEnds,
  peakSeasonStarts,
  highSeasonStarts,
  highSeasonEnds,
  untilDate,
  fromDate,
  priceHigh,
  priceLow,
  priceForHoliday,
  groupSize,
}: ComputeSeasonalPrice) {
  let price = 0;
  const oneDay = 86400000; //ms
  //check inputs
  if (!untilDate || !fromDate || !groupSize) {
    //TODO: error out
  } else {
    const diffTime = Math.abs(fromDate.getTime() - untilDate.getTime());
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let dateWindow = fromDate.getTime();

    const computeSeasonEdges = (...args: string[]) => {
      const [starts, ends, dateWindowYear] = args;
      const [seasonStartsYear] = starts.split("-");
      const [seasonEndsYear] = ends.split("-");
      if (seasonEndsYear === seasonStartsYear) {
        return {
          seasonStarts: starts.replace(/^[0-9]{4}/, dateWindowYear),
          seasonEnds: ends.replace(/^[0-9]{4}/, dateWindowYear),
        };
      }
      if (seasonEndsYear > seasonStartsYear) {
        return {
          runSplitComparison: true,
          one: {
            seasonStarts: starts.replace(/^[0-9]{4}/, dateWindowYear),
            seasonEnds: `${dateWindowYear}-12-31`,
          },
          two: {
            seasonStarts: `${dateWindowYear}-01-01`,
            seasonEnds: ends.replace(/^[0-9]{4}/, dateWindowYear),
          },
        };
      } else {
        throw new Error("season end can't be lesser than season start date"); //TODO: study implications of this throw
      }
    };

    while (diffDays > 0) {
      diffDays--;
      const [dateWindowYear] = moment(dateWindow)
        .startOf("day")
        .format("YYYY-MM-DD")
        .split("-");
      if (peakSeasonStarts && peakSeasonEnds) {
        const { seasonStarts, seasonEnds, runSplitComparison, one, two } =
          computeSeasonEdges(peakSeasonStarts, peakSeasonEnds, dateWindowYear);
        if (seasonStarts && seasonEnds && !runSplitComparison) {
          const peakSeasonStartsTime = new Date(seasonStarts).getTime(); // use same year as window
          const peakSeasonEndsTime = new Date(seasonEnds).getTime();
          if (
            dateWindow >= peakSeasonStartsTime &&
            dateWindow <= peakSeasonEndsTime
          ) {
            price += priceForHoliday;
            dateWindow += oneDay;
            continue;
          }
        }
        if (runSplitComparison) {
          //split
          if (
            dateWindow >= new Date(one?.seasonStarts as string).getTime() &&
            dateWindow <= new Date(one?.seasonEnds as string).getTime()
          ) {
            price += priceForHoliday;
            dateWindow += oneDay;
            continue;
          }
          if (
            dateWindow >= new Date(two?.seasonStarts as string).getTime() &&
            dateWindow <= new Date(two?.seasonEnds as string).getTime()
          ) {
            price += priceForHoliday;
            dateWindow += oneDay;
            continue;
          }
        }
      }
      if (highSeasonStarts && highSeasonEnds) {
        const { seasonStarts, seasonEnds, runSplitComparison, one, two } =
          computeSeasonEdges(highSeasonStarts, highSeasonEnds, dateWindowYear);
        if (seasonStarts && seasonEnds && !runSplitComparison) {
          const highSeasonStartsTime = new Date(seasonStarts).getTime(); // use same year as window
          const highSeasonEndsTime = new Date(seasonEnds).getTime();
          if (
            dateWindow >= highSeasonStartsTime &&
            dateWindow <= highSeasonEndsTime
          ) {
            price += priceHigh;
            dateWindow += oneDay;
            continue;
          }
        }
        if (runSplitComparison) {
          //split
          if (
            dateWindow >= new Date(one?.seasonStarts as string).getTime() &&
            dateWindow <= new Date(one?.seasonEnds as string).getTime()
          ) {
            price += priceHigh;
            dateWindow += oneDay;
            continue;
          }
          if (
            dateWindow >= new Date(two?.seasonStarts as string).getTime() &&
            dateWindow <= new Date(two?.seasonEnds as string).getTime()
          ) {
            price += priceHigh;
            dateWindow += oneDay;
            continue;
          }
        }
      }
      //is low
      price += priceLow;
      dateWindow += oneDay;
      continue;
    }
    return price / Number(groupSize);
  }
}

export const PriceService = {
  /**
   * Calculates transportation price based on the type and length of trip.
   */
  transportation(data: TransportationPriceData, options?: PriceOptions) {
    const {
      price,
      isThisAnAirportTransfer,
      trip,
      groupSize,
      activityType,
      tripLength,
    } = data;
    const mode = options?.mode || "perPerson"; //default to perPerson calculation
    const basePrice = computeBasePrice(
      { price, activityType, groupSize },
      mode
    );
    let computedPrice;
    if (isThisAnAirportTransfer) {
      if (trip === "one-way") {
        computedPrice = basePrice;
      } else {
        //is round-trip, must multiply by 2
        computedPrice = basePrice * 2;
      }
    } else {
      // is all inclusive, must multiply by triplength
      computedPrice = basePrice * Number(tripLength);
    }
    computedPrice = computePriceWithOptions(computedPrice, {
      ...options,
      mode,
    });
    return computedPrice;
  },
  housing(data: HousingPriceData, options?: PriceOptions) {
    const { groupSize } = data;
    let computedPrice;
    const seasonalPricePerPerson =
      computeSeasonalPrice({
        ...data,
        fromDate: new Date(data.fromDate),
        untilDate: new Date(data.untilDate),
      }) || 0;
    const mode = options?.mode || "perPerson"; //default to perPerson calculation
    if (mode === "total") {
      computedPrice = seasonalPricePerPerson * Number(groupSize);
    } else {
      computedPrice = seasonalPricePerPerson;
    }
    computedPrice = computePriceWithOptions(computedPrice as number, options);
    return computedPrice;
  },
  daytimeActivities(data: DaytimeActivitiesData, options?: PriceOptions) {
    const mode = options?.mode || "perPerson"; //default to perPerson calculation
    let computedPrice = computeBasePrice(data, mode);
    return computePriceWithOptions(computedPrice, { ...options, mode });
  },
  nighttimeActivities(data: NighttimeActivitiesData, options?: PriceOptions) {
    const mode = options?.mode || "perPerson"; //default to perPerson calculation
    let computedPrice = computeBasePrice(data, mode);
    return computePriceWithOptions(computedPrice, { ...options, mode });
  },
  personalServices(data: PersonalServicesData, options?: PriceOptions) {
    const { reach, fromDate, untilDate } = data;
    const mode = options?.mode || "perPerson"; //default to perPerson calculation
    let computedPrice = computeBasePrice(data, mode);
    const isTrip = reach === "trip";
    const priceMultiplier = isTrip
      ? daysDiff(
          new Date(`${fromDate}T00:00:00`),
          new Date(`${untilDate}T00:00:00`)
        )
      : 1;
    computedPrice = computedPrice * priceMultiplier; // trip price must be multiplied by duration of trip.
    return computePriceWithOptions(computedPrice, { ...options, mode });
  },
  computeSeasonalPrice,
};
