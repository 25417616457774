import { createGlobalStyle } from 'styled-components';
import { sm, md, lg, xl } from 'src/constants';

export const GlobalStyle = createGlobalStyle`
  body {
    background: #FFFFFF;
  }
  #root {
    min-height: 100vh;
  }
  img {
    object-fit: cover;
    max-width: 100%;
    object-position: center center;
  }
  .error-message {
    color: #CF2C78;
    margin: 0;
  }
  p {
    color:  ${({ theme }) => theme.colors.body};
  }
  body {
    overflow-x: hidden;
  }
  @keyframes fadein{
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }

  //Selected

  .selected{
    //TODO: implement this
  }

//PULSING

.pulsing{
  &:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  &:before {
    opacity: 1;
  }

  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
}

a{
  color:  ${({ theme }) => theme.colors.primary};
   &:hover{
     color:  ${({ theme }) => theme.colors.tertiary};
  }
}

.MuiPaper-root{
    overflow-x: auto !important;

  }
  .MuiCard-root{
    padding: 18px 10px;
    @media ${md}{
    padding: 18px 20px;
    }
  }

  @keyframes rotating {
    0%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }

  .rotatesync {
    transition: linear 3s;
    animation-name: rotating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }


.MuiInputBase-input{
  padding: 9px 12px;
  background: #F6F7F9;
  box-shadow: none;

}

.MuiOutlinedInput-root{
  background: #F6F7F9;
  svg, path{
    fill: #03A0EF;
  }
}

.MuiFormControl-root.MuiTextField-root{
  width: 100%;
}
`;
