import moment from "moment";
import {
  SUMMARY_HOUSING_QUERY_DATA,
  SUMMARY_HOUSING_QUERY,
  SUMMARY_TRANSPORTATION_QUERY_DATA,
  SUMMARY_TRANSPORTATION_QUERY,
  SUMMARY_DAYTIMEACTIVITIES_QUERY_DATA,
  SUMMARY_DAYTIMEACTIVITIES_QUERY,
  SUMMARY_NIGHTTIMEACTIVITIES_QUERY_DATA,
  SUMMARY_NIGHTTIMEACTIVITIES_QUERY,
  SUMMARY_PERSONALSERVICES_QUERY_DATA,
  SUMMARY_PERSONALSERVICES_QUERY,
  GROUP_DATA,
} from "src/app/queries";

export const computeTranspDataWithQty = (
  transportationData: SUMMARY_TRANSPORTATION_QUERY_DATA | undefined,
  group: GROUP_DATA
) => {
  return transportationData?.allTransportations?.map((transp) => {
    const match = group?.transportation?.find((x) => x.id == transp.id);
    return {
      ...transp,
      qty: match?.qty || 0,
    };
  });
};

export const computePersDataWithQty = (
  personalServicesData: SUMMARY_PERSONALSERVICES_QUERY_DATA | undefined,
  group: GROUP_DATA
) => {
  return personalServicesData?.allPersonalServices?.map((service) => {
    const match = group?.personalServices?.find((x) => x.id == service.id);
    return {
      ...service,
      qty: match?.qty || 0,
    };
  });
};

interface ObjectWithId {
  id?: string;
  qty?: number | string;
}

/**
 *
 * @param arr Array holding items
 * @param item item to toggle from array
 * @returns Array[T]
 */
export function toggleFromObjArray(
  arr: ObjectWithId[] | null,
  item: ObjectWithId
) {
  if (arr) {
    const indexOf = arr.map((x) => x.id).indexOf(item.id);
    if (indexOf !== -1) {
      //remove clicked
      const newarr = [...arr];
      newarr.splice(indexOf, 1);
      return newarr;
    } else {
      //add clicked
      return [...arr, item];
    }
  } else return [item]; //first clicked
}

/**
 *
 * @param fromDate Date
 * @param untilDate Date
 * @returns Number of days between two dates, rounds up.
 */
export const daysDiff = (fromDate: Date, untilDate: Date): number => {
  const diffTime = Math.abs(fromDate.getTime() - untilDate.getTime());
  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

/**
 * @function
 * Formats float to USD value including currency symbol
 */
const toUsd = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

/**
 *
 * @param number number to format as USD.
 * @returns string with number formatted as USD
 */
export function toUSD(number: number | string): string {
  return toUsd.format(Number(number));
}

export function toUSDNoDecimal(number: number): string {
  return `${toUSD(Math.round(number))}`.replace(/\D00(?=\D*$)/, "");
}
