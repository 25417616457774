/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { DateWrapper, Cards } from "./styled";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "src/components/shared/date";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { Formik } from "formik";
import Select from "src/components/shared/select";
import { Controller, useForm } from "react-hook-form";
import MembersTable from "./MembersTable";
import Person from "src/components/shared/icons/people";

import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Box,
  Button,
  Card,
  Grid,
  Skeleton,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import type { Customer } from "../../../../types/customer";
import wait from "../../../../utils/wait";
import { FromIcon } from "src/components/shared/date/FromIcon";
import moment, { Moment } from "moment";
import { TilIcon } from "src/components/shared/date/TilIcon";
import { Input } from "src/components/shared/input";
import { useNavigate, useParams } from "react-router-dom";
import { toUSD } from "../../../../utils/toUSD";
import { toggleFromArray } from "../../../../utils/toggleFromArray";
import PhoneInput from "src/components/shared/phone-input";
import DACard from "src/components/shared/card";
import { CardWrapper } from "src/components/shared";
import useEditGroup from "src/hooks/useEditGroup";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { daysDiff, toggleFromObjArray } from "src/utils";
import { PriceService } from "src/services/price";
import TransportationCard from "./transportationCard";
import { TransportationTrip } from "src/types/group";

interface CustomerEditFormProps {
  initialData?: any;
  initialLoading?: any;
  initialRefetch?: any;
  groupId?: string;
  create?: boolean;
}
export type FormValuesDestination = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  destination: string;
  fromDate: string;
  untilDate: string;
  travelReason: string;
  groupSize: string | number;
  payees: number | string;
  contactMethod: string;
  budget: string;
  housing: string;
};

export type FormRealValuesDestination = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  destination: string;
  fromDate: Moment;
  untilDate: Moment;
  travelReason: string;
  groupSize: number;
  contactMethod: string;
  budget: string;
  housing: string;
  payees?: string | number;
};

const UPDATE_GROUP = gql`
  mutation updateOneGroup($query: GroupQueryInput, $set: GroupUpdateInput!) {
    updateOneGroup(query: $query, set: $set) {
      _id
    }
  }
`;

const CREATE_GROUP = gql`
  mutation insertOneGroup($data: GroupInsertInput!) {
    insertOneGroup(data: $data) {
      _id
    }
  }
`;

const CustomerEditForm: FC<CustomerEditFormProps> = ({
  initialData,
  initialLoading,
  initialRefetch,
  groupId,
  create,
}) => {
  const navigate = useNavigate();
  const [updateOneGroup] = useMutation(UPDATE_GROUP);
  const [insertOneGroup] = useMutation(CREATE_GROUP);

  const defaultValues = {
    firstName:
      !create && initialData?.group?.firstName
        ? initialData?.group?.firstName
        : "",
    lastName:
      !create && initialData?.group?.lastName
        ? initialData?.group?.lastName
        : "",
    email:
      !create && initialData?.group?.email ? initialData?.group?.email : "",
    phone:
      !create && initialData?.group?.phone ? initialData?.group?.phone : "",
    destination:
      !create && initialData?.group?.destination
        ? initialData?.group?.destination?.id
        : "",
    fromDate:
      !create && initialData?.group?.fromDate
        ? initialData?.group?.fromDate
        : new Date().toISOString(),
    untilDate:
      !create && initialData?.group?.untilDate
        ? initialData?.group?.untilDate
        : new Date(new Date().getTime() + 86400000).toISOString(),
    travelReason:
      !create && initialData?.group?.travelReason
        ? initialData?.group?.travelReason
        : "",
    groupSize:
      !create && initialData?.group?.groupSize
        ? initialData?.group?.groupSize
        : "",
    contactMethod:
      !create && initialData?.group?.contactMethod
        ? initialData?.group?.contactMethod
        : "",
    budget:
      !create && initialData?.group?.budget ? initialData?.group?.budget : "",
    housing:
      !create && initialData?.group?.housing
        ? initialData?.group?.housing?.id
        : "",
    payees:
      !create && (initialData?.group?.payees || initialData?.group?.groupSize)
        ? initialData?.group?.payees || initialData?.group?.groupSize
        : "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    control,
    setValue,
    reset,
  } = useForm<FormValuesDestination>({
    defaultValues,
  });

  const [transportations, setTransportations] = useState<
    | null
    | {
        id?: string;
        qty?: number | string;
        name?: string;
        price?: string;
        trip: TransportationTrip;
      }[]
  >(null);

  const [daytimeActivities, setDaytimeActivities] = useState<null | string[]>(
    null
  );

  const [nighttimeActivities, setNighttimeActivities] = useState<
    null | string[]
  >(null);

  const [personalServices, setPersonalServices] = useState<
    | null
    | { id?: string; qty?: number | string; name?: string; price?: string }[]
  >(null);
  console.log(transportations, "transportations");
  useEffect(() => {
    if (!isSubmitting && !create && initialData !== undefined) {
      reset(defaultValues);
      if (!create && initialData?.group?.transportation) {
        setTransportations([...initialData?.group?.transportation]);
      }
      if (!create && initialData?.group?.daytimeActivities) {
        setDaytimeActivities([
          ...initialData?.group?.daytimeActivities?.map((el) => el.id),
        ]);
      }
      if (!create && initialData?.group?.nighttimeActivities) {
        setNighttimeActivities([
          ...initialData?.group?.nighttimeActivities?.map((el) => el.id),
        ]);
      }
      if (!create && initialData?.group?.personalServices) {
        setPersonalServices([...initialData?.group?.personalServices]);
      }
    }
  }, [initialLoading]);

  const {
    housingData,
    loadingHousing,
    transportationData,
    loadingTransportation,
    daytimeActivitiesData,
    loadingDaytimeActivities,
    nighttimeActivitiesData,
    loadingNighttimeActivities,
    personalServicesData,
    loadingPersonalServices,
    destinations,
    destinationOptions,
    reasons,
    options,
    methods,
    budgetOptions,
    housingOptions,
    transportationOptions,
  } = useEditGroup(watch);

  const tilDateMin = () => {
    const watched = new Date(watch(["fromDate"])[0]);
    if (watched) {
      return new Date(watched.getTime() + 86400000); //add a day
    } else {
      return new Date(new Date().getTime() + 86400000); //add a day
    }
  };

  const tripLength = `${daysDiff(
    new Date(watch("fromDate")),
    new Date(watch("untilDate"))
  )}`;

  const groupSize = watch("groupSize");

  const onSubmit = async (data: FormRealValuesDestination) => {
    try {
      const values = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        destination: destinations?.allDestinations
          ?.filter((el) => el.id === data.destination)
          ?.map((el) => {
            return {
              id: el.id,
              place: el.place,
              country: el.country,
              name: `${el.place.trim()} - ${el.country.trim()}`,
            };
          })[0],
        fromDate: moment(data.fromDate).startOf("day").format("YYYY-MM-DD"),
        untilDate: moment(data.untilDate).startOf("day").format("YYYY-MM-DD"),
        tripLength,
        travelReason: data.travelReason,
        groupSize: data.groupSize,
        contactMethod: data.contactMethod,
        budget: data.budget,
        housing: housingData?.allHousings
          ?.filter((el) => el.id === data.housing)
          ?.map((el) => {
            return {
              id: el.id,
              priceLow: el.priceLow,
              priceHigh: el.priceHigh,
              priceForHoliday: el.priceForHoliday,
              name: el.name,
            };
          })[0],
        transportation: transportations?.map(
          ({ id, qty, name, price, trip }) => {
            return {
              id,
              qty,
              name,
              price,
              trip,
            };
          }
        ),
        daytimeActivities: daytimeActivitiesData?.allDaytimeActivities
          ?.filter((el) => daytimeActivities?.includes(el.id))
          ?.map((el) => {
            return {
              id: el.id,
              price: el.price,
              name: el.name,
            };
          }),
        nighttimeActivities: nighttimeActivitiesData?.allNighttimeActivities
          ?.filter((el) => nighttimeActivities?.includes(el.id))
          ?.map((el) => {
            return {
              id: el.id,
              price: el.price,
              name: el.name,
            };
          }),
        personalServices: personalServices?.map(({ id, qty, name, price }) => {
          return {
            id,
            qty,
            name,
            price,
          };
        }),
        payees: data.payees,
      };

      if (!create) {
        const res = await updateOneGroup({
          variables: {
            query: {
              _id: groupId,
            },
            set: {
              ...values,
            },
          },
        });
        toast.success("Group updated successfully!");
        console.log(res);
      } else {
        console.log(data, "data");
        const res = await insertOneGroup({
          variables: {
            data: {
              ...values,
              createdByAdmin: true,
            },
          },
        });
        toast.success("Group Created successfully!");
        navigate(`/groups/${res.data.insertOneGroup._id}`);
        console.log(res);
      }
      initialRefetch();
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Box sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              {!initialLoading ? (
                <Input
                  fullWidth
                  placeholder="Josh"
                  label="First Name"
                  {...register("firstName", {
                    required: {
                      value: true,
                      message: "First name is required",
                    },
                  })}
                  error={errors?.firstName?.message}
                />
              ) : (
                <Skeleton animation="wave" height={"80px"} width={"300px"} />
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              {!initialLoading ? (
                <Input
                  fullWidth
                  placeholder={"Smith"}
                  label="Last Name"
                  {...register("lastName", {
                    required: { value: true, message: "Last name is required" },
                  })}
                  error={errors?.lastName?.message}
                />
              ) : (
                <Skeleton animation="wave" height={"80px"} width={"300px"} />
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              {!initialLoading ? (
                <Input
                  fullWidth
                  placeholder="jsmith@domain.com"
                  label="Email"
                  {...register("email", {
                    required: { value: true, message: "Email is required" },
                  })}
                  type="email"
                  error={errors?.email?.message}
                />
              ) : (
                <Skeleton animation="wave" height={"80px"} width={"300px"} />
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              {!initialLoading ? (
                <Controller
                  control={control}
                  name="phone"
                  rules={{
                    required: {
                      value: true,
                      message: "Phone number is required",
                    },
                  }}
                  render={({ field }) => (
                    <PhoneInput
                      {...{
                        ...field,
                        error: errors?.phone?.message,
                      }}
                    />
                  )}
                />
              ) : (
                <Skeleton animation="wave" height={"80px"} width={"300px"} />
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              {destinationOptions && !initialLoading ? (
                <Select
                  label="DESTINATION"
                  {...register("destination", {
                    required: {
                      value: true,
                      message: "Please choose a destination",
                    },
                  })}
                  options={destinationOptions}
                  error={errors?.destination?.message}
                />
              ) : (
                <Skeleton animation="wave" height={"80px"} width={"300px"} />
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              {!initialLoading ? (
                <DateWrapper
                  style={{
                    marginBottom: errors?.fromDate?.message ? "50px" : "0px",
                  }}
                >
                  <Controller
                    name="fromDate"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please select a date",
                      },
                    }}
                    render={({ field }) => (
                      <DatePicker
                        icon={<FromIcon />}
                        label="from when"
                        datePickerProps={field}
                        error={errors?.fromDate?.message}
                      />
                    )}
                  />
                </DateWrapper>
              ) : (
                <Skeleton animation="wave" height={"80px"} width={"300px"} />
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              {!initialLoading ? (
                <DateWrapper
                  style={{
                    marginBottom: errors?.fromDate?.message ? "50px" : "0px",
                  }}
                >
                  <Controller
                    name="untilDate"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please select a date",
                      },
                    }}
                    render={({ field }) => (
                      <DatePicker
                        icon={<TilIcon />}
                        label="till when"
                        datePickerProps={field}
                        minDate={tilDateMin()}
                        error={errors?.untilDate?.message}
                      />
                    )}
                  />
                </DateWrapper>
              ) : (
                <Skeleton animation="wave" height={"80px"} width={"300px"} />
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              {!initialLoading ? (
                <Select
                  label="TRAVEL REASON"
                  {...register("travelReason", {
                    required: {
                      value: true,
                      message: "Please choose a reason",
                    },
                  })}
                  options={reasons}
                  error={errors?.travelReason?.message}
                />
              ) : (
                <Skeleton animation="wave" height={"80px"} width={"300px"} />
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              {!initialLoading ? (
                <Select
                  label="GROUP SIZE"
                  {...register("groupSize", {
                    required: {
                      value: true,
                      message: "Please choose your group size",
                    },
                  })}
                  options={options}
                  error={errors?.groupSize?.message}
                />
              ) : (
                <Skeleton animation="wave" height={"80px"} width={"300px"} />
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              {!initialLoading ? (
                <Select
                  label="BUDGET"
                  {...register("budget", {
                    required: {
                      value: true,
                      message: "Please choose your budget",
                    },
                  })}
                  options={budgetOptions}
                  error={errors?.budget?.message}
                />
              ) : (
                <Skeleton animation="wave" height={"80px"} width={"300px"} />
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              {!initialLoading ? (
                <Select
                  label="CONTACT METHOD"
                  {...register("contactMethod", {
                    required: {
                      value: true,
                      message: "Please choose a contact method",
                    },
                  })}
                  options={methods}
                  error={errors?.contactMethod?.message}
                />
              ) : (
                <Skeleton animation="wave" height={"80px"} width={"300px"} />
              )}
            </Grid>

            <Grid item md={3} xs={12}>
              {!initialLoading ? (
                <Select
                  label="PAYEES"
                  {...register("payees", {
                    required: {
                      value: true,
                      message: "Please select number of payees",
                    },
                  })}
                  options={options}
                  error={errors?.payees?.message}
                />
              ) : (
                <Skeleton animation="wave" height={"80px"} width={"300px"} />
              )}
            </Grid>
            <Grid item md={3} />
            {/* <Grid item md={12} xs={12}>
              <MembersTable watch={watch} />
            </Grid> */}
            <Grid item md={3} xs={12}>
              {!initialLoading &&
              housingData !== undefined &&
              housingData?.allHousings.length !== 0 &&
              !loadingHousing ? (
                <Select
                  label="HOUSING"
                  {...register("housing")}
                  options={housingOptions}
                  error={errors?.housing?.message}
                />
              ) : loadingHousing ? (
                <Skeleton animation="wave" height={"80px"} width={"300px"} />
              ) : (
                <p>
                  No housings available for this destination and group size
                  combination.
                </p>
              )}
            </Grid>
            <Grid item md={9} />
            <Grid item md={6} xs={12}>
              <Typography marginBottom="40px" component="h4" variant="h4">
                Transportations
              </Typography>
              <Cards>
                {!initialLoading &&
                transportationData !== undefined &&
                !loadingTransportation ? (
                  transportationData?.allTransportations?.map(
                    (transportationFetched, i) => {
                      return (
                        <TransportationCard
                          transportations={transportations}
                          transportationFetched={transportationFetched}
                          groupSize={groupSize}
                          tripLength={tripLength}
                          setTransportations={setTransportations}
                        />
                      );
                    }
                  )
                ) : initialLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  "No daytime activities available for this destination and group size combination."
                )}
              </Cards>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography marginBottom="40px" component="h4" variant="h4">
                Daytime Activities
              </Typography>
              <Cards>
                {!initialLoading &&
                daytimeActivitiesData !== undefined &&
                !loadingDaytimeActivities ? (
                  daytimeActivitiesData?.allDaytimeActivities?.map(
                    (daytimeActivitiesfetched, i) => {
                      const selected = daytimeActivities?.includes(
                        daytimeActivitiesfetched.id
                      );
                      const price = PriceService.daytimeActivities(
                        {
                          groupSize,
                          price: daytimeActivitiesfetched.price,
                          activityType: daytimeActivitiesfetched.activityType,
                        },
                        {
                          usd: true,
                          labeled: true,
                          noCeroCero: true,
                          rounded: true,
                        }
                      );
                      const cardProps = {
                        image:
                          daytimeActivitiesfetched.cardImage.responsiveImage,
                        title: daytimeActivitiesfetched.name,
                        icons: [
                          {
                            Icon: Person,
                            text: daytimeActivitiesfetched.groupSizeMin + "+",
                          },
                        ],
                        paragraph: daytimeActivitiesfetched.shortDescription,
                        // button1: { text: 'LEARN MORE', onClick: () => console.log('click') },
                        button2: {
                          text: selected
                            ? "Remove Activity"
                            : "Select Activity",
                          onClick: () =>
                            setDaytimeActivities((prev) => {
                              return toggleFromArray(
                                prev,
                                daytimeActivitiesfetched.id
                              );
                            }),
                        },
                        featured: daytimeActivitiesfetched.featured,
                        selected,
                        price,
                      };
                      return (
                        <CardWrapper key={daytimeActivitiesfetched.id}>
                          <DACard {...cardProps} />
                        </CardWrapper>
                      );
                    }
                  )
                ) : initialLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  "No daytime activities available for this destination and group size combination."
                )}
              </Cards>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography marginBottom="40px" component="h4" variant="h4">
                Personal Services
              </Typography>
              <Cards>
                {!initialLoading &&
                personalServicesData !== undefined &&
                !loadingPersonalServices ? (
                  personalServicesData?.allPersonalServices?.map(
                    (personalServicesfetched, i) => {
                      const selected = personalServices
                        ?.map((x) => x.id)
                        ?.includes(personalServicesfetched?.id);
                      const thisPersonalService =
                        initialData?.group?.personalServices?.find(
                          (x) => x.id == personalServicesfetched?.id
                        );
                      const qty = thisPersonalService?.qty;
                      const price = PriceService.nighttimeActivities(
                        {
                          groupSize,
                          price: personalServicesfetched.price,
                          activityType: personalServicesfetched.activityType,
                        },
                        {
                          usd: true,
                          labeled: true,
                          noCeroCero: true,
                          rounded: true,
                        }
                      );
                      const cardProps = {
                        image:
                          personalServicesfetched?.cardImage?.responsiveImage ||
                          null,
                        title: personalServicesfetched.name,
                        withQty: true,
                        initialQty: qty || 1,
                        icons: [
                          {
                            Icon: Person,
                            text: personalServicesfetched.groupSizeMin + "+",
                          },
                        ],
                        paragraph: personalServicesfetched.shortDescription,
                        // button1: { text: 'LEARN MORE', onClick: () => console.log('click') },
                        button2: {
                          text: selected ? "Remove" : "Select",
                          onClick: (qty) =>
                            setPersonalServices((prev) => {
                              return toggleFromObjArray(prev, {
                                ...personalServicesfetched,
                                qty,
                              });
                            }),
                        },
                        featured: personalServicesfetched.featured,
                        selected,
                        price,
                      };
                      return (
                        <CardWrapper key={personalServicesfetched.id}>
                          <DACard {...cardProps} />
                        </CardWrapper>
                      );
                    }
                  )
                ) : loadingPersonalServices ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  "No personal services available for this destination and group size combination."
                )}
              </Cards>
            </Grid>
            <Grid item />
          </Grid>
        </Box>
      </Card>
      <Box sx={{ position: "sticky", bottom: "32px", ml: "32px", mt: 2 }}>
        <Button
          color="primary"
          disabled={isSubmitting}
          type="submit"
          variant="contained"
        >
          {create ? "Submit" : "Update Group"}
        </Button>
      </Box>
    </form>
  );
};

CustomerEditForm.propTypes = {
  // @ts-ignore
  customer: PropTypes.object.isRequired,
};

export default CustomerEditForm;
