// eslint-disable-next-line import/no-anonymous-default-export
const People = (props: any) => {
  return (
    <svg
      id="Group_1312"
      {...props}
      data-name="Group 1312"
      xmlns="http://www.w3.org/2000/svg"
      width="11.829"
      height="13.503"
      viewBox="0 0 11.829 13.503"
    >
      <g id="Group_1310" data-name="Group 1310" transform="translate(2.092)">
        <path
          id="Path_1226"
          data-name="Path 1226"
          d="M2167.822-665.856a3.826,3.826,0,0,1-3.822-3.822,3.826,3.826,0,0,1,3.822-3.822,3.826,3.826,0,0,1,3.822,3.822A3.826,3.826,0,0,1,2167.822-665.856Zm0-6.7a2.877,2.877,0,0,0-2.874,2.874,2.877,2.877,0,0,0,2.874,2.874,2.877,2.877,0,0,0,2.874-2.874A2.877,2.877,0,0,0,2167.822-672.551Z"
          transform="translate(-2164 673.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1311" data-name="Group 1311" transform="translate(0 6.696)">
        <path
          id="Path_1227"
          data-name="Path 1227"
          d="M2137.855-546.693a.474.474,0,0,1-.474-.474,7.654,7.654,0,0,0-.781-3.937,3.257,3.257,0,0,0-3.043-1.448h-2.283a3.258,3.258,0,0,0-3.044,1.448,7.657,7.657,0,0,0-.781,3.937.474.474,0,0,1-.475.474.474.474,0,0,1-.474-.474c0-2.719.495-6.333,4.773-6.333h2.283c4.278,0,4.773,3.614,4.773,6.333A.474.474,0,0,1,2137.855-546.693Z"
          transform="translate(-2126.5 553.5)"
          fill="#bac3c9"
        />
      </g>
    </svg>
  );
};

export default People;
