import styled from 'styled-components';
import { md, lg } from 'src/constants';

export const Form = styled.form`
  width: 100%;
  height: 100%;
  min-height: 72vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media ${lg} {
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  @media ${md} {
  }
  @media ${lg} {
  }
`;

export const CardWrapper = styled.div`
  margin-bottom: 24px;
  max-width: 300px;
  width: 100%;
  @media ${md} {
    width: 200px;
    margin-right: 20px;
  }
  @media ${lg} {
    margin-right: 30px;
  }
`;
