import React, { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import CreateQuote from './components/dashboard/create-quote';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/PasswordRecovery'))
);
const PasswordReset = Loadable(
  lazy(() => import('./pages/authentication/PasswordReset'))
);
const Register = Loadable(
  lazy(() => import('./pages/authentication/Register'))
);
const VerifyCode = Loadable(
  lazy(() => import('./pages/authentication/VerifyCode'))
);

// Dashboard pages

const GroupDetails = Loadable(
  lazy(() => import('./pages/dashboard/GroupDetails'))
);

const GroupList = Loadable(lazy(() => import('./pages/dashboard/GroupList')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));

// Error pages

// const AuthorizationRequired = Loadable(
//   lazy(() => import('./pages/AuthorizationRequired'))
// );
// const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
// const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const CustomerEdit = Loadable(
  lazy(() => import('./pages/dashboard/CustomerEdit'))
);

const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: 'register-unguarded',
        element: <Register />,
      },
      {
        path: 'verify-code',
        element: <VerifyCode />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Overview />,
      },
      {
        path: 'create-quote',
        element: <CreateQuote />,
      },
      {
        path: 'groups',
        children: [
          {
            path: '/',
            element: <GroupList />,
          },
          {
            path: ':groupId',
            element: <GroupDetails />,
          },
          {
            path: ':groupId/edit',
            element: <CustomerEdit />,
          },
        ],
      },
      {
        path: 'mail',
        children: [
          {
            path: '/',
            element: <Navigate to="/dashboard/mail/all" replace />,
          },
        ],
      },
    ],
  },
];

export default routes;
